form{
    background-color: #f9f9f9;
    padding: 10px 20px;
    border-radius: 6px;

    div{
        padding: 5px 0;
    }

    .ck-editor__editable {
        min-height: 500px;
    }

    input{
        width: 100%;
        @include input-style;
    }

    select{
        width: 44%;
        @include input-style;
    }
    button{
        @include input-style;
        width: 10%;
        padding: 0;
        background-color: red;
        color: #fff;
    }

    textarea{
        width: calc(100% - 20px);
        height: 100px;
        line-height: 18px;
        padding: 10px;
        border:1px solid #e5e5e5;
        resize: none;
    }

    .picture{
        width: 200px;
        height: 120px;
        background-color: #eee;
        padding: 0;
        margin: 0;
        position: relative;
        input{
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            z-index: 1;
        }
    }

    .flex{
        display: flex;
        justify-content: space-between;

        textarea{
            width: calc(100% - 30px - 200px);
            height: auto;
        }
    }
}