.main{
    display: table;
    width: 100%;
    height: 100%!important;
}
.rightSidebar, .leftSidebar{
    display: table-cell;
    vertical-align: top;
}

.leftSidebar{
    width: 227px;
    padding: 47px 15px;
    background-color: #ddd;
    ul{
        width: 100%;
        height: 100%;
        position: fixed;
        overflow-y: scroll;
    }
    li{
        padding: 14px 0;
        border-top: 1px solid #eee;
        a:first-child{
            margin-right:10px;
        }
        span{
            padding: 5px 0 0 0;
            display: block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}


main{
    h1{
        margin:0;
        padding: 8px 0;
        font-size: 20px;
    }
}

.content{
    background-color: #fff;
    height: 100%;
}