table{
	width: 100%;
	border-collapse: collapse;
	margin: 0;	
	background-color: #fff;
}

thead{background-color: #f8f8f8;}

th, td{
	padding: 15px;
	border: 1px solid #e5e5e5;
	color: #444;
	text-align: left;
}


tbody tr:nth-child(n+1):hover{
	background-color: rgb(230, 255, 197);
}

td{
	img{
		width: 160px;
	}

	h3{
		font-weight: bold;
	}

	h4{
		padding: 10px  0 0 0;
		color: #007ac3;
		font-weight: normal;

		span{
			display: inline-block;
			margin-left: 10px;

			&:first-child{
				color: red;
				margin: 0;
			}
		}
	}

	h5{
		padding: 10px 0 0 0;
		color: #222;
		font-weight: normal;

		label{
			display: inline-block;
			color: gray;
			width: 70px;
			&:nth-child(2){
				width: 80px;
				margin-left: 20px
			}
		}
	}

	.btnEdit{
		line-height: 35px;
		border-radius: 6px;
		padding: 0 12px;
		background-color: #ddd;
		border: 1px solid #ccc;
		cursor: pointer;
		outline: none;
	}

	input {
		line-height: 30px;
		padding: 0;
		text-indent: 10px;
		width: 100%;
		border: 1px solid #e5e5e5;
		border-radius: 3px;
		outline: orangered;
	}
} 