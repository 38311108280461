@include breakpoint(879px, 'max'){
    
    #i-menu{
        right: 10px;
        background: transparent url(../images/menu-2.png) no-repeat center center;
    }
    #i-search{
        right: 50px;
        background: transparent url(../images/search-2.png) no-repeat center center;
    }

    #i-menu.close, #i-search.close{
        background-image: url(../images/close.png);
    }

    #i-menu, #i-search{
        position: absolute;
        top: 8px;
        z-index: 999;
        cursor: pointer;
        transition: background-image 0.5s ease-in-out;
        width: 30px;
        height: 40px;
        background-size: contain;
    }
}

@include breakpoint(200px){
    header{
        background-color: $mainBGColor;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        height: $headerHeightMobile;
        div{
            padding: 12px 10px 4px 10px;
            position: relative;
        }
        .logo{
            display: inline-block;
            h1, h2, span{
                display: inline-block;
                position: relative;
                font-weight: normal;
                white-space: nowrap;
                line-height: 33px;
                // letter-spacing: 1px;
                word-spacing: -7px;
                font-size: 24px;
                // font-family: arial; //Tahoma;
                color: #fff;
                text-shadow: 1px 1px 0 red;
                margin-left: 5px;
                max-width: 157px;
                &::after{
                    content:"com.vn";
                    position: absolute;
                    right: 0;
                    top: -18px;
                    font-size: 11px;
                    letter-spacing: 1.1px;
                }
                &::first-letter{
                    text-transform: uppercase;
                }
            }
        }
        img{
            height: 30px;
            float: left;
        }
        strong{
            font-weight: normal;
            color: yellow;
        }
       
    }
}

@include breakpoint(880px){
    header{
        height: 50px;
        div{
            // max-width: 1400px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: 200px auto;
            padding: 0 15px;
        }
        .logo{
            padding: 10px 0 0 0;
            h1, h2, span{
                font-size: 20px;
                width: 142px;
                letter-spacing: 1.2px;
                &::after{
                    font-size: 10px;
                    top: -15px;
                }
            }
        }
        
    }
}