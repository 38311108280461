.products{
	overflow: hidden;
	ul{
		display: grid;
		grid-template-columns: minmax(30%, 33.4%) minmax(30%, 33.4%) minmax(30%, 33.4%);
		grid-gap: 20px;
	}
	li{
		padding: 0 0 30px 0;
		span{
			display: inline-block;
			color: red;
			margin: 0;
			padding:0;
			font-weight: bold;
			&:nth-child(3) {
				border-left: 1px solid orange;
				padding: 0 5px;
				margin:0 5px 5px 5px;
			}
		}
		em{
			display: block;
			font-style: normal;
		}
		label{
			display: inline-block;
			color: red;
			margin: 0 5px 0 0;
			padding:0;
			text-transform: capitalize;
		}
		p{
			padding: 0;
			margin: 5px 0 0 0;
			line-height: 16px;
			color: gray;
			label{
				display: inline-block;
				width: 40px;
				color: #333;
			}
		}
	}
	h4{
		font-weight: normal;
		line-height: 18px;
		height: 37px;
		padding: 0;
		margin: 6px 0;
		overflow: hidden;
	}
	div{
		color: #043b83;
	}
}