.f-login{
    background:#aaaaaa40;
    padding:10px 20px;
    width:320px;
    margin:150px auto 0 auto;
    border-radius:5px;
    h2{
        background:url(../images/icons/login.png) no-repeat 0 0;
        margin:0 0 5px 0;
        padding:5px 0 5px 30px;
        font-size:18px;
        color:#444;
    }
    hr{
        height: 1px;
        border: none;
        border-bottom:1px dotted #66666657;
        margin:0 0 10px 0;
        padding:0
    }
    p{overflow:hidden;padding:5px 0;margin:0;}
    label{width:75px;float:left;text-align:right; line-height: 30px;}
    input {
        border:1px solid #ccc;
        border-radius:3px;
        padding: 0;
        line-height: 30px;
        width: 200px!important;
        background-color: #f5f5f5;
        float: right;
    }
    .button{
        width:80px!important;
        cursor:pointer;
        float:right;
        margin-left:10px;
        background-color: #ccc;
        line-height: 30px;
        text-indent: 0;
    }
}