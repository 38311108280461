@mixin breakpoint($point, $type:'min') {
    @if($type=='min'){
        @media only screen and (min-width: $point) { @content ; }
    }
    @else{
        @media only screen and (max-width: $point) { @content ; }
    }
}

@mixin maxWidth {
	width: 100%;
    max-width: $maxWidth;
    margin: 0 auto;
	padding: 0 15px;
}

@mixin imgFixed {
	min-height: 100%;
    min-width: 100%;
    object-fit: cover;
}

@mixin input-style{
    line-height: 35px;
    padding: 0;
    text-indent: 10px;
    border:1px solid #e5e5e5;
    border-radius: 3px;
}