@include breakpoint(200px){
	nav{
		font-size: 22px;
		line-height: 60px;
		color: #fff;
		display: none;
		width: 80%;//220px;
		height: auto;
		overflow: hidden;
		background-color: orange;
		position: absolute;
		left: -300px;
		top: 55px;
		z-index: 11;
		a{
			display: block;
			padding: 0 18px;
			border-bottom: 1px solid #f5efe76b;
			&:hover{
				color: yellow;
			}
		}

		li{
			ul{
				a{
					padding-left: 35px;
					border-color:#f5efe733;
				}
			}
		}

		ul{
			&:first-child{
				a{
					background: url(../images/arrow.png) no-repeat 17px 20px;
					padding-left: 54px;
					color: yellow;
					border: none;
					padding-left: 50px;
				}
			}
			li{
				border: none;
			}
		}
	}
}

@include breakpoint(880px){
	nav{
		font-size: 16px;
		line-height: 40px;
		display: block;
		width: auto;
		overflow: unset;
		position: unset;
		background-color: transparent;

		li{
			float: left;
			position: relative;
			border-left: 1px solid #f25858;
 			&:hover ul{
 				display: block;
 			}
		}

		a{
			border-bottom: none;
			line-height: 50px;
			border-left: 1px solid #fdac65;
		}

		ul{
			&:first-child{
				float: right;
				a{
					background: url(../images/arrow.png) no-repeat 22px 15px;
					padding: 0 0 0 45px;
					color: yellow;
				}
				li{
					border: none;
				}
			}

			ul{
				display: none;
				position: absolute;
				top: 50px;
				left: 0px;
				z-index: 15;
				background-color: orange;
				width: 270px;
				display: none;
				li{
					float: none;
					display: block;
					border-left: none;
				}
				a{
					border-top: 1px solid #f2b950;
					border-left: none;
					padding:0 22px;
					line-height: 40px;
				}
				a.pa{
					padding-left: 20px;
				}
			}
		}
	}
}