html, body, #root{height: 100%;}
body{
    background-color: $bgColor;
    font-family: $fontMain;
    color: $fontColor;
    font-size: $fontSize;
    line-height: 1.5;
    padding: 50px 0;
}
main, .cnt, .search, footer > div{
	margin: 0 auto;
	padding: 0 15px;
}

.hide{display: none!important;}
.hot{color: red!important;}
.warm{color: darkorange!important;}
.pass{color: green!important;}
.false{color: gray!important;}

.w-40{width: 40px;}
.w-50{width: 50px;}
.w-60{width: 60px;}
.w-80{width: 80px;}
.w-100{width: 100px;}
.w-120{width: 120px;}
.w-150{width: 150px;}
.w-200{width: 200px;}
.a-left{text-align: left!important;}
.a-right{text-align: right!important;}
.a-center{text-align: center!important;}
.text-cap{text-transform: capitalize!important;}

.table{display: table; width: 100%;}