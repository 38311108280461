article, aside{
	display: table-cell;
	vertical-align: top;
	padding: 20px;
	background-color: #f5f5f5;
}

article{
	width: 600px;
	border-right: 10px solid #eee;

	h2{
		font-size: 18px;
		font-weight: normal;
		text-transform: uppercase;
		margin: 20px 0 10px 0;
		color: red;
	}

	h4{
		margin: 20px 0 0 0;
	}

	hgroup{
		padding: 0 0 20px 0;
		border-bottom: 1px solid #e5e5e5;
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
		span{
			&:first-child{
				line-height:40px;
			}
			&:last-child{
				text-align: right;
			}
		}
	}

	.info{
		white-space: nowrap;
		text-overflow: ellipsis;
		font-size: 16px;

		span, em{
			display: inline-block;
		}

		em{
			color: #043b83;
			font-style: normal;
		}

		span:nth-child(2){
			border-left: 1px solid orange;
			padding-left: 10px;
		}
	}

	li{
		display: table;
		width: 100%;
		border-top: 1px solid #f9f9f9;

		label, strong, em{
			display: table-cell;
			vertical-align: middle;
			padding: 8px 0;
		}

		label{
			width: 100px;
		}

		em{
			padding: 5px 0 5px 5px;
		}

		p{
			margin: 10px 0;
			padding: 0;
			line-height: 20px;
			font-size: 14px;
			white-space: pre-wrap;
		}

		br{
			line-height: 200%;
		}

		.date{
			text-align: right;
			margin: 20px 0 0 0;
		}

	}

}


.pictures{
	margin: 20px 0 50px 0;
	width: 600px;
	height: 450px;
	div{
		height: 100%;
		width: 100%;
	}

	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}